export let settings = {
  Ginger: {
    type: 'fdf',
    general: {
      filament_diameter: 2.5,
      design_space: { x: 1000, y: 1000, z: 1000 },
      nozzle: 3,
    },
    wall: {
      min_height: 0.5,
      max_height: 2,
      max_speed: 10,
      max_flowrate: 9999,
    },
    base: {},
    firmware: {
      start_code: `
; Ginger One V1.2 betate
; --- START GCODE -
; activate temperature control
G4 P100
M42 P27 I T1 S0
M42 P27 I T1 S0
G4 P100
; set units to mm
G21
; disable cold extrusion checking
M302 P1
; set mixer values
G4 P100
M163 S0 P1
G4 P100
M163 S1 P0.025
G4 P100
M164
G4 P100
; raise zeta before homing (or probing!)
G91
G1 F200 Z5
G90
G4 P100
; homing
G28
G4 P100
; enable bed leveling
; M420 S1
; bed levelling (set custom area)
; G29 F150 L150 R300 B3
; make sure extruder’s ready
M300 P200
G4 P500
M300 P200
M0 Click if EXTR. READY
; --- end of START GCODE ---
`,
      end_code: `; --- END GCODE ---
; deactivate temperature control
M42 P27 I T1 S1
M42 P27 I T1 S1
; zeta raise
;G91
;G1 F100 Z30
;G90
; Safe deactivate temperature control
M42 P27 I T1 S1
M42 P27 I T1 S1
; X homing
G28 X
; deactivate motors
M84
; Activate temperature control
G4 P2000
M42 P27 I T1 S0
M42 P27 I T1 S0
G4 P2000
; finishing tone
M300 P500
G4 P1000
M300 P500
G4 P1000
M300 P1000
; -- end of END GCODE ---
`,
    },
  },
  Weber: {
    type: 'fdf',
    general: {
      filament_diameter: 1.11,
      design_space: { x: 1000, y: 1000, z: 1000 },
      nozzle: 3,
    },
    wall: {
      min_height: 1.9,
      max_height: 2.1,
      max_speed: 100,
      max_flowrate: 9999,
      min_layer_time: 1,
    },
    base: {
      height: 10,
    },
    //performance: {
    //  min_distance: 1.5,
    //  max_distance: 3.0,
    //},
    firmware: {
      start_code: `G90
M82
M106 S0 P0
M104 S190 T0
M109 S190 T0
T0
M102
G1 E-70.0000 F99999
M103
M101
`,
      end_code: ``,
    },
  },
  Colossus: {
    type: 'fdf',
    general: {
      filament_diameter: 5,
      design_space: { x: 1000, y: 1000, z: 1000 },
      nozzle: 5,
    },
    wall: {
      min_height: 1.7,
      max_height: 2.2,
      max_speed: 100,
      max_flowrate: 9999,
      min_layer_time: 1,
    },
    base: {
      ramp_up: 1,
    },
    //performance: {
    //  min_distance: 1.5,
    //  max_distance: 3.0,
    //},
    firmware: {
      start_code: `G90
M82
M106 S0
M104 S225 T0
; process Process1
; layer 1, Z = 4.000
T0
; feature skirt
; tool H2.500 W7.000
G1 X0.000 Y0.000 Z4.000 F1500
M101
`,
      end_code: `
M103
M104 S0 ; turn off extruder
M140 S0 ; turn off bed
M84 ; disable motors
`,
    },
  },
  SideWinder: {
    type: 'fdm',
    general: {
      nozzle: 1.2,
      design_space: { x: 300, y: 300, z: 400 },
    },
    wall: {
      min_height: 0.1,
      max_height: 1.0,
      max_speed: 30,
      max_flowrate: 15,
      extrusion_multiplier: 1.0,
    },
    base: {
      ramp_up: 10,
      brim: {
        use: true,
        height: 0.6,
        rounds: 3,
      },
    },
    firmware: {
      start_code: `M140 S60 ; set bed temperature
M105 ; report temperature
M190 S60 ; wait for bed temperature
M104 S240 ; set hotend temperature
M105 ; report temperatures
M109 S240 ; wait for hotend temperature
M82 ;absolute extrusion mode
G28 ; home all axes
M117 Purge extruder
G92 E0 ; reset extruder
G21 ; set units to millimeters
`,
      end_code: `G91; relative positioning
G1 Z1.0 F3000 ; move z up little to prevent scratching of print
G90; absolute positioning
G1 X0 Y200 F1000 ; prepare for part removal
M104 S0; turn off extruder
M140 S0 ; turn off bed
G1 X0 Y300 F1000 ; prepare for part removal
M84 ; disable motors
M106 S0 ; turn off fan`,
    },
  },
  Alfawise_Ceramic: {
    type: 'fdf',
    general: {
      filament_diameter: 2.47487,
      nozzle: 4,
      design_space: { x: 300, y: 300, z: 400 },
    },
    wall: {
      min_height: 0.8,
      max_height: 1.6,
      max_speed: 120,
      max_flowrate: 120,
      extrusion_multiplier: 1.0,
      adaptive_layerheights: {
        on: false,
      },
      adaptive_wallthickness: {
        on: false,
      },
    },
    base: {
      ramp_up: 10,
      brim: {
        use: true,
        height: 0.6,
        rounds: 0,
      },
      fill: {
        socket: 'native',
        fill_method: 'offset',
        height: 1,
        layers: 2,
      },
    },
    firmware: {
      start_code: `;-- START GCODE --;
M82
G28 X Y
G92 E0.000
M104 S200
M109 S200
;-- End of START GCODE --;
`,
      end_code: `;-- END GCODE --;
G92 E0.000
G92 X Y Z
G0 Z 10
;-- End of END GCODE --;`,
    },
  },
  default: {
    type: 'fdm',
    general: {
      filament_diameter: 1.75,
      design_space: { x: 200, y: 200, z: 200 },
      nozzle: 0.4,
      radial: false,
      z_offset: 0,
    },
    wall: {
      min_height: 0.1,
      max_height: 0.3,
      max_speed: 30,
      max_flowrate: 10,
      min_layer_time: 1,
      adaptive_layerheights: {
        on: true,
        min_overlap: 0.3,
      },
      adaptive_wallthickness: {
        on: true,
      },
      extrusion_multiplier: 1.0,
    },
    base: {
      ramp_up: 1,
      brim: {
        height: 1,
        rounds: 3,
      },
      skirt: {
        height: 1,
        rounds: 1,
      },
      fill: {
        socket: 'native',
        fill_method: 'offset',
        height: 1,
        layers: 2,
      },
    },
    performance: {
      min_distance: "",
      max_distance: "",
      oversampling: 10,
      guidepoints: 20,
    },
    checking: {
      max_curvature: -0.2,
    },
    firmware: {
      start_code: '',
      end_code: '',
    },
  },
}
