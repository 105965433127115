import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useStyleConfig,
  useToast,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  Textarea,
} from '@chakra-ui/react'

import { merge, assocPath, get as get_in } from 'lodash/fp'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { GoChevronDown } from 'react-icons/go'
import { useState } from 'react'
import { MeshMaker } from 'lib/mesh-maker'
import { Input } from '@chakra-ui/react'
import { settings } from './printer-settings'
import { nil } from 'lib/utils'
import { on } from 'events'

//const BACKEND_HOST = "https://backend.lyla-design.com"
const BACKEND_HOST = process.env.NEXT_PUBLIC_BACKEND_HOST || "http://localhost:8080"
const LOGIN_URL = BACKEND_HOST + "/api/v1/auth/login"
const CHECK_SESSION_URL = BACKEND_HOST + "/api/v1/auth/check-session"
const LOGOUT_URL = BACKEND_HOST + "/api/v1/auth/logout"
const SLICE_URL = BACKEND_HOST + "/api/v1/slicer/slice"

export function read_from_storage() {
  let token = localStorage.getItem("backend-session-id")
  return nil(token) ? "" : token
}

function write_to_storage(session_id) {
  if (nil(session_id)) {
    localStorage.removeItem("backend-session-id")
  } else {
    localStorage.setItem("backend-session-id", session_id)
  }
}

// (js/localStorage.getItem "session-id")
// (js/localStorage.setItem "session-id" (pr-str new-value))



function dispatch(status, data, on_success, on_error) {
  if (status === 200) {
    on_success(data)
  } else {
    on_error(data, status)
  }
}

function default_success(data) {
  console.log(data)
}

function default_error(data, status) {
  console.log(data, status)
}

//
function ensure_fn(prefered, backup) {
  return nil(prefered) ? backup : prefered
}

function post(url, params, token, on_success, on_error) {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  if (!nil(token)) {
    headers["Authorization"] = token
  }
  let success_fn = ensure_fn(on_success, default_success)
  let error_fn = ensure_fn(on_error, default_error)
  fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(params)
  }).then(response => response.json().then(data => dispatch(response.status, data, success_fn, error_fn)))
}

function persist_login(data) {
  write_to_storage(data["session-id"])
  return "logged_in"
}

function persist_logout() {
  write_to_storage(null)
  return "logged_out"
}


export function login(email, password, on_success, on_error) {
  let success_fn = (data) => {
    if (data.status === "ok"){
      let new_state = persist_login(data)
      on_success(new_state, data)
    } else {
      let new_state = persist_logout()
      on_error(new_state, data, 200)
    }
  }
  let error_fn = (data, status) => {
    let new_state = persist_logout()
    on_error(new_state, data, status)
  }
  post(LOGIN_URL, { email: email, password: password }, null, success_fn, error_fn)
  return "logging_in"
}

export function check_session(on_success, on_error) {
  let success_fn = (data) => {
    if (data.status === "ok"){
      let new_state = persist_login(data)
      on_success(new_state, data)
    } else {
      let new_state = persist_logout()
      on_error(new_state, data, 200)
    }
  }
  let error_fn = (data, status) => {
    let new_state = persist_logout()
    on_error(new_state, data, status)
  }
  post(CHECK_SESSION_URL, { authorization: read_from_storage() }, null, success_fn, error_fn)
  return "checking_session"
}

export function logout(on_success, on_error) {
  let success_fn = (data) => {
    let new_state = persist_logout()
    on_success(new_state, data)
  }
  let error_fn = (status) => {
    let new_state = persist_logout()
    on_error(new_state, data, status)
  }
  post(LOGOUT_URL, { authorization: read_from_storage() }, null, success_fn, error_fn)
  return "logging_out"
}

export function slice(data, on_success, on_error) {
  post(SLICE_URL, data, read_from_storage(), on_success, on_error)
}
