import {
  Button,
  Flex,
  Input,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { BiCopy } from 'react-icons/bi'
import { ShapeCurve } from 'lib/mesh-maker'
import { AnimatedButton } from '../action-button-drawer'
import { Spline } from 'lib/spline-classes'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import { NextRouter, useRouter } from 'next/router'
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Sphere } from 'lib/sphere'
import SpinnerIcon from '../icons/spinner'
import { GeometryState, WebsiteState, getAllObjectsSerialized } from 'lib/state'
import { schemaVersion } from 'lib/constants'

export async function saveToDatabase(
  websiteState: WebsiteState,
  uuid = uuidv4()
) {
  try {
    var res: any = await fetch(window.location.origin + '/api/urls', {
      method: 'POST',
      body: JSON.stringify({
        _id: uuid,
        value: getAllObjectsSerialized(websiteState.exportedObjects),
        version: schemaVersion,
      }),
    })
  } catch (e) {
    console.log('error', e)
    res = { status: 500 }
  }

  return { uuid, res }
}

export class CopyFunctions {
  static async copyURL(
    router: NextRouter,
    websiteState: WebsiteState,
    setUrlValue: Dispatch<SetStateAction<string>>,
    onToggle: () => void,
    redirectURL?: string
  ) {
    const uuid = uuidv4()
    var { res } = await saveToDatabase(websiteState, uuid)
    if (res.status == 200)
      await router.replace(
        {
          pathname: '/',
          query: {
            id: uuid,
          },
        },
        undefined,
        { shallow: true }
      )

    if (res.status == 200) {
      const el = document.createElement('input')
      el.value = window.location.href
      setUrlValue(window.location.href)

      document.body.appendChild(el)
      el.select()
      const copied = document.execCommand('copy')

      if (copied) {
        if (redirectURL)
          alert(
            'URL Copied To Your Clipboard. You will be directed to etsy site.'
          )
        else alert('URL Copied To Your Clipboard.')

        if (redirectURL) router.push(redirectURL)
      } else {
        onToggle()
      }

      await router.replace(
        {
          pathname: '/',
          query: {},
        },
        undefined,
        { shallow: true }
      )
      document.body.removeChild(el)
    } else {
      console.log(res)
      alert(
        'URL WAS NOT COPIED. Warning your design was not saved. You loose your design if you leave the site.'
      )
    }
  }

  static secondaryCopy(
    router: NextRouter,
    copyRef: MutableRefObject<HTMLInputElement>,
    onToggle: () => void,
    redirectURL?: string
  ) {
    copyRef.current.select()
    const copied = document.execCommand('copy')

    if (copied) {
      if (redirectURL)
        alert(
          'URL Copied To Your Clipboard. You will be directed to etsy site.'
        )
      else alert('URL Copied To Your Clipboard.')

      onToggle()

      if (redirectURL)
        router.push(
          'https://www.etsy.com/de-en/listing/1408386236/custom-3d-printed-sustainable-lamp?ref=yr_purchases'
        )
    } else {
      alert(
        'URL WAS NOT COPIED. Warning your design was not saved. You loose your design if you leave the site.'
      )
    }
  }
}

export default function CopyButton({
  buttonColor,
  geometryState,
  websiteState,
  router,
}: {
  buttonColor: string
  geometryState: GeometryState
  websiteState: WebsiteState
  router: NextRouter
}) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const popoverPlacement = useBreakpointValue({ base: 'top', md: 'left' })
  const [urlValue, setUrlValue] = useState('')
  const copyRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const copyLoaderCall = async () => {
    setIsLoading(true)
    await CopyFunctions.copyURL(router, websiteState, setUrlValue, onToggle)
    setIsLoading(false)
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        //@ts-ignore
        placement={popoverPlacement}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Flex
            width={{ base: 'unset', md: '4em' }}
            height={{ md: 'unset', base: '4em' }}
          />
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Copy URL</PopoverHeader>

          <PopoverBody>
            <Flex direction={'column'} justifyContent={'flex-end'}>
              <Input ref={copyRef} value={urlValue} />
              <Button
                marginTop={'0.5em'}
                onClick={() =>
                  CopyFunctions.secondaryCopy(
                    router,
                    copyRef as MutableRefObject<HTMLInputElement>,
                    onToggle
                  )
                }
              >
                Copy
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <AnimatedButton
        buttonColor={buttonColor}
        function={copyLoaderCall}
        isLoading={isLoading}
        loadingIcon={SpinnerIcon}
        icon={BiCopy}
        text={'Copy URL'}
      />
    </>
  )
}

export function CopyButtonEtsy({
  buttonColor,
  borderColor,
  websiteState,
  geometryState,
  router,
}: {
  buttonColor: string
  borderColor: string
  geometryState: GeometryState
  websiteState: WebsiteState
  router: NextRouter
}) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const popoverPlacement = useBreakpointValue({ base: 'top', md: 'left' })
  const [urlValue, setUrlValue] = useState('')
  const copyRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const redirectURL =
    'https://www.etsy.com/de-en/listing/1408386236/custom-3d-printed-sustainable-lamp?ref=yr_purchases'

  const copyLoaderCall = async () => {
    setIsLoading(true)
    await CopyFunctions.copyURL(
      router,
      websiteState,
      setUrlValue,
      onToggle,
      redirectURL
    )
    setIsLoading(false)
  }

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        //@ts-ignore
        placement={popoverPlacement}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Flex
            width={{ base: 'unset', md: '4em' }}
            height={{ md: 'unset', base: '4em' }}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Copy URL</PopoverHeader>
          <PopoverBody>
            <Flex direction={'column'} justifyContent={'flex-end'}>
              <Input ref={copyRef} value={urlValue} />
              <Button
                marginTop={'0.5em'}
                onClick={() =>
                  CopyFunctions.secondaryCopy(
                    router,
                    copyRef as MutableRefObject<HTMLInputElement>,
                    onToggle,
                    redirectURL
                  )
                }
              >
                Copy
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <AnimatedButton
        isLoading={isLoading}
        loadingIcon={SpinnerIcon}
        buttonColor={buttonColor}
        function={copyLoaderCall}
        borderColor={borderColor}
      />
    </>
  )
}
