import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useStyleConfig,
} from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useRef } from 'react'
import { GoChevronDown } from 'react-icons/go'
import { ModelType, modelTypes } from 'lib/constants'
import { getInitialShapes } from 'lib/shape-classes'
import { getInitialSpline } from 'lib/spline-classes'
import { GeometryState, WebsiteState } from 'lib/state'
import { MeshMaker } from 'lib/mesh-maker'

interface LampPickerProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function LampPicker({
  geometryState,
  websiteState,
}: LampPickerProps) {
  const menuButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null)
  const menuButtonDimensions = useSize(menuButtonRef)
  const styles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  const onOptionChange = (value: ModelType) => {
    websiteState.setZRotationMultiplier(0)
    geometryState.setPreviousStates([])
    if (value.id !== modelTypes.stacker.id) {
      if (value.editorType != geometryState.modelType.editorType) {
        const generatedGeometryState = GeometryState.generateInitialState(
          value,
          undefined,
          geometryState.color,
          undefined,
          [],
          [],
          undefined,
          undefined,
          undefined
        )

        websiteState.setExportedObjects([generatedGeometryState])
        websiteState.setSelectedObjectIndex(0)
        geometryState.importState(generatedGeometryState)
        websiteState.setRandomSalt((v) => (v + 1) % 3)
      } else {
        geometryState.spline.knots[0].position.x = 0
        geometryState.setModelType(value)
      }
    } else {
      const generatedGeometryState1 = GeometryState.generateInitialState(
        value,
        undefined,
        geometryState.color,
        undefined,
        [],
        [],
        undefined,
        undefined,
        undefined
      )

      const generatedGeometryState2 = GeometryState.generateInitialState(
        value,
        undefined,
        geometryState.color,
        undefined,
        [],
        [],
        undefined,
        undefined,
        true
      )

      const meshMaker1 = new MeshMaker(generatedGeometryState1, false, false)
      const meshMaker2 = new MeshMaker(generatedGeometryState2, false, false)

      const doneMesh1 = meshMaker1.getMesh(true, false)
      const doneMesh2 = meshMaker2.getMesh(true, false)

      websiteState.setExportedObjects([
        generatedGeometryState1,
        generatedGeometryState2,
      ])

      websiteState.setMeshObject([doneMesh1[0], doneMesh2[0]])
      websiteState.setBoundingBox([doneMesh1[1], doneMesh2[1]])

      websiteState.setSelectedObjectIndex(0)
      geometryState.importState(generatedGeometryState1)
      websiteState.setRandomSalt((v) => (v + 1) % 3)
    }
  }

  const textColor = useColorModeValue('black', 'white')
  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            __css={styles}
            ref={menuButtonRef}
            marginTop={'1em'}
            width={'80%'}
            _hover={{ filter: 'brightness(60%)' }}
          >
            <Flex
              hidden={!websiteState.sidebarIsOpen}
              width={'100%'}
              height={'100%'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              MODEL:{' '}
              {geometryState.modelType
                ? geometryState.modelType.displayName
                : ''}
              <Flex alignItems={'center'}>
                |
                <GoChevronDown />
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList
            overflowY={'auto'}
            maxH={'40vh'}
            backgroundColor={'#' + geometryState.color}
            width={
              menuButtonDimensions ? menuButtonDimensions.width : 'inherit'
            }
            zIndex={6}
            color={textColor}
          >
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.leroyMerlin)}
            >
              <Flex>{modelTypes.leroyMerlin.displayName}</Flex>
            </MenuItem>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.e27)}
            >
              {' '}
              {modelTypes.e27.displayName}
            </MenuItem>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.bigLamp)}
            >
              {' '}
              {modelTypes.bigLamp.displayName}
            </MenuItem>

            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.vase)}
            >
              {' '}
              {modelTypes.vase.displayName}
            </MenuItem>

            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.smallVase)}
            >
              {' '}
              {modelTypes.smallVase.displayName}
            </MenuItem>

            <Box>
              <hr />
            </Box>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.pablo)}
            >
              {' '}
              {modelTypes.pablo.displayName}
            </MenuItem>
            <Box>
              <hr />
            </Box>
            {/* <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.boucle)}
            >
              {' '}
              {modelTypes.boucle.displayName}
            </MenuItem> */}
            <Box>
              <hr />
            </Box>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.sketch)}
            >
              {' '}
              {modelTypes.sketch.displayName}
            </MenuItem>
            <Box>
              <hr />
            </Box>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.bubble)}
            >
              {' '}
              {modelTypes.bubble.displayName}
            </MenuItem>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.candle)}
            >
              {' '}
              {modelTypes.candle.displayName}
            </MenuItem>
            <MenuItem
              _hover={{
                fontSize: '1.5em',
                backgroundColor: 'rgba(0,0,0,0)',
              }}
              transition='all .1s ease'
              onClick={() => onOptionChange(modelTypes.stacker)}
            >
              {' '}
              {modelTypes.stacker.displayName}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
